$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

fireSwal = function(type, title, message, callback) {
    if(type != 'warning' && type != 'update' && type != 'approve' && type != 'deny' && type != 'add') {
        Swal.fire(
            title,
            message,
            type
        )
    } else if(type == 'warning') {
        Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then(callback);
    } else if(type == 'update') {
        Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#33dd58',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Update it!'
        }).then(callback);
    }   else if(type == 'approve') {
        Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#33dd58',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Approve it!'
        }).then(callback);
    }   else if(type == 'deny') {
        Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, deny it!'
        }).then(callback);
    }   else if(type == 'add') {
        Swal.fire({
            title: title,
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#33dd58',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, add it!'
        }).then(callback);
    }
}

fireTostr = function(status, message) {
    toastr.options = {
                      "positionClass": "toast-top-right",
                      "preventDuplicates": true,
                      "onclick": null,
                      "showDuration": "3000",
                      "hideDuration": "1000",
                      "timeOut": "5000",
                      "extendedTimeOut": "1000",
                      "showEasing": "swing",
                      "hideEasing": "linear",
                      "showMethod": "fadeIn",
                      "hideMethod": "fadeOut"
                    };
    switch (status) {
        case 'success':
            toastr.success(message);
            break;
        case 'warning':
            toastr.warning(message);
            break;
        default:
            toastr.error(message);
            break;
    }
}

$(document).ready(function () {
    //section start to disable past dates
    var today = new Date().toISOString().split('T')[0];
    $('#start_date').attr('min', today);
    $('#end_date').attr('min', today);
    //section end to disable past dates
    
    var currentDate = moment().format("YYYY-MM-DD");

    $('#report_start_date, #report_end_date').daterangepicker({
        locale: {
              format: 'YYYY-MM-DD'
        },
        "alwaysShowCalendars": true,
        // "minDate": currentDate,
        // "maxDate": moment().add('months', 1),
        autoApply: true,
        autoUpdateInput: false,
        timePicker: true,
        timePicker24Hour: true
    }, function(start, end, label) {
      // console.log("New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')");
      // Lets update the fields manually this event fires on selection of range
      var selectedStartDate = start.format('YYYY-MM-DD'); // selected start
      var selectedEndDate = end.format('YYYY-MM-DD'); // selected end

      $startDateInput = $('#report_start_date');
      $endDateInput = $('#report_end_date');

      // Updating Fields with selected dates
      $startDateInput.val(selectedStartDate);
      $endDateInput.val(selectedEndDate);

      // Setting the Selection of dates on calender on CHECKOUT FIELD (To get this it must be binded by Ids not Calss)
      var endDatePicker = $endDateInput.data('daterangepicker');
      endDatePicker.setStartDate(selectedStartDate);
      endDatePicker.setEndDate(selectedEndDate);

      // Setting the Selection of dates on calender on CHECKIN FIELD (To get this it must be binded by Ids not Calss)
      var startDatePicker = $startDateInput.data('daterangepicker');
      startDatePicker.setStartDate(selectedStartDate);
      startDatePicker.setEndDate(selectedEndDate);

    });

    $(document).on('change', '#avatar', function(event){
        var reader = new FileReader();
        reader.onload = function(){
            var output = document.getElementById('output');
            output.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
    })

    $('.select2').select2({
        width: '100%'
    });

    $('.category-select').select2({
        placeholder: 'Select Category',
        width: '100%',
    });

    $('.multi-select').select2({
        placeholder: 'Select The Band Name',
        tokenSeparators: [",", " "],
    });
})