import './../../site-assets/config.js';



let usersEventsTable = '';
let events = function() {
    let initUsersEventsDatatables = function() {
        usersEventsTable = $('#users-events-list').DataTable({
            responsive: true,
            processing: true,
            serverSide: true,
            ordering: true,
            sortable: true,
            searching: true,
            pageLength: 25,
            order: [ 0, "desc" ],
            ajax: {
                url : $('#users-events-list').data('url'),
                type : 'POST'
            },
            columns: [
                {data: 'id', name: 'id', visible: false},
                {data: 'name', name: 'name'},
                {data: 'start_date', name: 'start_date'},
                {data: 'end_date', name: 'end_date'},
                {data: 'status',name: 'status'},
                {data: 'actions', name: 'actions', orderable: false, searchable:false}
            ]
        });
    }

    let eventValidation = function() {

        $("#create-event").validate({
            ignore: "",
            errorClass: "text-danger",
            rules: {
                customer_name: "required",
                customer_email: {required: true, email: true},
                customer_phone: {
                    required: true,
                    phoneFormat: true
                },
                ad_duration: "required",
                name: "required",
                start_date: "required",
                start_time: "required",
                end_date: { required:true,greaterThan: "#start_date" },
                "category_id[]": "required",
                "band_name[]": "required",
                ad_type: "required",
                description: "required",
                url: {
                    // required: true,
                    url: true
                },
                audio_url: {
                    required:'#audio:checked',
                    url: true
                 },
                 video_url: {
                    required:'#video:checked',
                    url: true
                 },
                'flyer_images[]': {
                   required:'#image:checked'
                }
            },
            messages: {
                customer_name: "Please enter your name",
                customer_email: {
                    required: "Please enter your email",
                    email: "Please enter a valid email address.",
                },
                customer_phone: {
                    required: "Please enter your phone",
                },
                ad_duration: "Please select ad duration",
                name: "Please enter event name",
                start_date: "Please select start date",
                start_time: "Please select start time",
                end_date: { required: "Please select end date",greaterThan: "End date must be greater than start date" },
                "category_id[]": "Please select category",
                "band_name[]": "Please enter band name",
                ad_type: "Please select ad type",
                description: "Please enter description",
                audio_url: { required: "Please enter event audio url",url: "Invalid Url, please enter valid event audio url" },
                video_url: { required: "Please enter event video url",url: "Invalid Url, please enter valid event video url" },
                url: {
                    required: "Please enter event url",
                    url: "Invalid Url, please enter valid event url"
                },
            },
            errorPlacement: function (error, element) {
                if (element.hasClass('select2') && element.next('.select2-container').length) {
                    error.insertAfter(element.next('.select2-container'));
                } else if((element.hasClass('multi-select') || element.hasClass('category-select')) && element.next('.select2-container').length) {
                    error.insertAfter(element.next('.select2-container'))
                }
                else {
                    error.insertAfter(element);
                }
            }
        });

        $.validator.addMethod("phoneFormat", function(value, element) {
            return this.optional(element) || value.match(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
        }, "Please enter phone number format (XXX-XXX-XXXX)");

        //Edit event
        $("#edit-event").validate({
            ignore: "",
            errorClass: "text-danger",
            rules: {
                customer_name: "required",
                customer_email: {required: true, email: true},
                customer_phone: {
                    required: true,
                    phoneFormat: true
                },
                ad_duration: "required",
                name: "required",
                start_date: "required",
                start_time: "required",
                end_date: { required:true,greaterThan: "#start_date" },
                category_id: "required",
                "band_name[]": "required",
                ad_type: "required",
                description: "required",
                url: {
                    // required: true,
                    url: true
                },
                audio_url: {
                    required:'#audio:checked',
                    url: true
                },
                video_url: {
                    required:'#video:checked',
                    url: true
                },
                // 'flyer_images[]': {
                //    required:'#image:checked'
                // }
            },
            messages: {
                customer_name: "Please enter your name",
                customer_email: {
                    required: "Please enter your email",
                    email: "Please enter a valid email address.",
                },
                customer_phone: {
                    required: "Please enter your phone",
                },
                ad_duration: "Please select ad duration",
                name: "Please enter event name",
                start_date: "Please select start date",
                start_time: "Please select start time",
                end_date: { required: "Please select end date",greaterThan: "End date must be greater than start date" },
                category_id: "Please select category",
                "band_name[]": "Please enter band name",
                ad_type: "Please select ad type",
                description: "Please enter description",
                audio_url: { required: "Please enter event audio url",url: "Invalid Url, please enter valid event audio url" },
                video_url: { required: "Please enter event video url",url: "Invalid Url, please enter valid event video url" },
                url: {
                    required: "Please enter event url",
                    url: "Invalid Url, please enter valid event url"
                },
            },
            errorPlacement: function (error, element) {
                if (element.hasClass('select2') && element.next('.select2-container').length) {
                    error.insertAfter(element.next('.select2-container'));
                } else if(element.hasClass('multi-select') && element.next('.select2-container').length) {
                    error.insertAfter(element.next('.select2-container'))
                }
                else {
                    error.insertAfter(element);
                }
            }
        });
    }

    let UserProfileValidations = function() {
        //Edit user
        $("#edit-user").validate({
            ignore: "",
            errorClass: "text-danger",
            rules: {
                first_name: "required",
                last_name: "required",
                phone: "required",
                password: { minlength: 6, maxlength: 30 },
                confirmPassword: { equalTo: "#password" },
            },
            messages: {
                first_name: "Please enter first name",
                last_name: "Please enter last name",
                phone: "Please enter phone",
            },
        });
    }

    let removeImageFromDatabase = function(){
        $(document).on('click', '.delete-image', function(){
            let eventId = $(this).attr('data-id');
            fireSwal('warning', 'Are you sure?', "Are you sure to delete image. it will be be deleted permanently.", (result) => {
                //alert(result.value);
                if (result.value) {
                    $.ajax({
                        url: base_url + '/event-images/' + eventId,
                        type: 'GET',
                        dataType: "JSON",
                        data: {"_method": 'get'},
                        success:function(response) {
                            imgIndex--;
                            $('#image_count').val(imgIndex).change();
                            onRemoveClick(eventId)
                        }
                    });
                }
            });

            const onRemoveClick = (id) => {
                $('#image_button_'+id).closest('div').remove();//remove parent div
                // imgIndex--;
            }
        });
    }

    let contactUsFormValidation = function() {
        $("#contact-form").validate({
            ignore: "",
            errorClass: "text-danger",
            rules: {
                name: "required",
                email: {required: true, email: true},
                phone: {
                    required: true,
                    phoneFormat: true
                },
                start_time: "required",
                subject: "required",
                message: "required"
            },
            messages: {
                name: "Please enter your name",
                email: {
                    required: "Please enter your email address",
                    email: "Please enter a valid email address.",
                },
                phone: {
                    required: "Please enter your phone number",
                },
                time: "Please select best time to contact",
                subject: "Please enter subject",
                message: "Please enter message",
            },
            errorPlacement: function (error, element) {
                    error.insertAfter(element);
            }
        });
    }

    let contactUs = function() {
        $(document).on('click', '#contact-form-btn', function(){
            if($("#contact-form").valid()){
                var data = $('#contact-form').serialize();
                $(".loader").show();
                $.ajax({
                    url: base_url + '/pages/contact-us-details',
                    type: 'POST',  
                    data: data,
                    success:function(response) {
                        $(".loader").hide();
                        let title = 'Error!';
                        if(response.status == 'success') {
                            title = 'Done!';
                        }
                        Swal.fire({
                            title: title,
                            text: response.message,
                            type: 'success',
                            icon: 'success',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.href = base_url + '/';
                            }
                        });
                    }
                });
            }
        })
    }

    $('input[type="radio"]').click(function(){
        if ($(this).is(':checked')) {
            $("#flyer_image_label").val("");
            if($(this).val() == 'text'){
                $("#flyer_image_label").addClass('d-none');
                $("#audio_label").addClass('d-none');
                $("#video_label").addClass('d-none');
                $("#flyer_images-error").addClass('d-none');
                $("#audio_url-error").addClass('d-none');
                $("#video_url-error").addClass('d-none');
                $("#imagesPreview").addClass('d-none');
            } else if($(this).val() == 'image') {
                $("#flyer_image_label").removeClass('d-none');
                $("#audio_label").addClass('d-none');
                $("#video_label").addClass('d-none');
                $("#audio_url-error").addClass('d-none');
                $("#video_url-error").addClass('d-none');
                $("#imagesPreview").removeClass('d-none');
            } else if($(this).val() == 'audio') {
                $("#audio_label").removeClass('d-none');
                $("#flyer_image_label").addClass('d-none');
                $("#video_label").addClass('d-none');
                $("#flyer_images-error").addClass('d-none');
                $("#video_url-error").addClass('d-none');
                $("#imagesPreview").addClass('d-none');
            } else if ($(this).val() == 'video') {
                $("#video_label").removeClass('d-none');
                $("#flyer_image_label").addClass('d-none');
                $("#audio_label").addClass('d-none');
                $("#flyer_images-error").addClass('d-none');
                $("#audio_url-error").addClass('d-none');
                $("#imagesPreview").addClass('d-none');
            }
        }
    });

    let getAdTypesData = function() {
        var adType = $("input[name='ad_type']:checked").val();
        if(adType === 'text') {
            $("#flyer_image_label").addClass('d-none');
            $("#audio_label").addClass('d-none');
            $("#video_label").addClass('d-none');
            $("#flyer_images-error").addClass('d-none');
            $("#audio_url-error").addClass('d-none');
            $("#video_url-error").addClass('d-none');
            $("#imagesPreview").addClass('d-none');
        }else if(adType === 'image') {
            $("#flyer_image_label").removeClass('d-none');
            $("#audio_label").addClass('d-none');
            $("#video_label").addClass('d-none');
            $("#audio_url-error").addClass('d-none');
            $("#video_url-error").addClass('d-none');
            $("#flyer_images-error").removeClass('d-none');
            $("#imagesPreview").removeClass('d-none');
        }else if(adType === 'audio') {
            $("#audio_label").removeClass('d-none');
            $("#flyer_image_label").addClass('d-none');
            $("#video_label").addClass('d-none');
            $("#flyer_images-error").addClass('d-none');
            $("#video_url-error").addClass('d-none');
            $("#audio_url-error").removeClass('d-none');
            $("#imagesPreview").addClass('d-none');
        }else if(adType === 'video') {
            $("#video_label").removeClass('d-none');
            $("#flyer_image_label").addClass('d-none');
            $("#audio_label").addClass('d-none');
            $("#flyer_images-error").addClass('d-none');
            $("#audio_url-error").addClass('d-none');
            $("#video_url-error").removeClass('d-none');
            $("#imagesPreview").addClass('d-none');
        }
    }
    
    let getDurationDates = function() {
        $("#start_date, #ad_duration").change(function () {
            var startDate = $("#start_date").val();
            var duration = $("#ad_duration").val();
            if(startDate && duration) {
                var date = moment(startDate).add(duration, "months").format("YYYY-MM-DD");
                $("#end_date").val(date);
            }
        });
    }
    
    // Search cross button clicked and show index
	$(document).on('search', '.search', function () {
		let id = $(this).attr("data-id");
		$(id).submit();
	});
    
    let userEventData = function() {
        $.ajax({
            url: base_url + '/get-user-data',
            type: 'get',
            dataType: 'json',
            success: function(response){
                if(response != null){
                    $('#customer_name').val(response.first_name);
                    $('#customer_email').val(response.email);
                    $('#customer_phone').val(response.phone);
                }
            }
        });
    }
      
    //Delete event
    $(document).on('click', '.delete-user-event', function(){
        let eventId = $(this).attr('data-id');
        fireSwal('warning', 'Are you sure?', "You want to delete this event!", (result) => {
            if (result.value) {
                $.ajax({
                    url: base_url + '/my-events/' + eventId,
                    type: 'DELETE',
                    dataType: "JSON",
                    data: {"_method": 'DELETE'},
                    success:function(response) {
                        let title = 'Error!';
                        if(response.status == 'success') {
                            title = 'Deleted!';
                            usersEventsTable.ajax.reload();
                        }
                        fireSwal(response.status, title, response.message);
                    }
                });
            }
        });
    });

    let calculateEventCost = function() {
        $("#category_id, #ad_duration, .ad_type, #flyer_images, #image_count").change(function () {
            // var images = 0;
            setTimeout(function(){
                var categoryId = $("#category_id").val();
                var duration = $("#ad_duration").val();
                var images = $('#image_count').val();// $("#imagesPreview img").length;
                var adType = $(".ad_type:checked").val();
                if(categoryId && duration) {
                    $.ajax({
                        url: base_url + '/get-event-cost',
                        type: 'POST',
                        dataType: "JSON",
                        data: {"category_id": categoryId, "duration": duration, "ad_type": adType, 'images': images },
                        success:function(response) {
                            $('#cost').val(0);
                            if(response.status == 'success'){
                                $('#cost').val(response.price);
                            }
                        }    
                    });
                }
            }, 500);
        });
    }

    return {
        // public functions
        init: function() {
            userEventData();
            getAdTypesData();
            eventValidation();
            initUsersEventsDatatables();
            getDurationDates();
            UserProfileValidations();
            removeImageFromDatabase();
            contactUsFormValidation();
            contactUs();
            calculateEventCost();
        }
    };
}();

 
/*Orders Table*/

let orderTable = '';
let orders = function() {
    let initDatatables = function() {
        orderTable = $('#user-ordersList').DataTable({
            responsive: true,
            processing: true,
            serverSide: true,
            ordering: true,
            searching: true,
            pageLength: 25,
            order: [ 0, "desc" ],
            ajax: {
                url : $('#user-ordersList').data('url'),
                type : 'POST'
            },
            columns: [
                {data: 'id', name: 'id', visible: false},
                {data: 'order_id', name: 'order_id'},
                {data: 'event_name', name: 'event_name'},
                {data: 'total_cost', name: 'total_cost'},
                {data: 'order_date',name: 'order_date'},
                {data: 'status',name: 'status'},
                {data: 'actions', name: 'actions', orderable: false, searchable:false}
            ]
        });
    } 

    let orderListing = function() {
        initDatatables();
    }

    return {
        // public functions
        init: function() {
            orderListing();
        }
    };
}();


function changeHamburgerColor(){
   $("#change_hamburger_color").toggleClass('text-warning');
}

$( document ).ready(function() {

    setInterval(changeHamburgerColor, 1000);
    $('#status').change(function(){
        usersEventsTable.draw();
    });

    $(".category-select").select2({
        placeholder: 'Select Category',
        width: '100%'
    });

    $(".multi-select").select2({
        placeholder: 'Select Band Name',
        tags: true,
        // tokenSeparators: [',', ' '],
        width: '100%'
    });

    events.init();

    jQuery.validator.addMethod("greaterThan", 
    function(value, element, params) {

        if (!/Invalid|NaN/.test(new Date(value))) {
            return new Date(value) >= new Date($(params).val());
        }

        return isNaN(value) && isNaN($(params).val()) 
            || (Number(value) >= Number($(params).val())); 
    },'Must be greater than {0}.');
    

    $('#reload').click(function () {
        $.ajax({
            type: 'GET',
            url: '/reload-captcha',
            success: function (data) {
                $(".captcha-img").html(data.captcha);
            }
        });
    });
    
    $('.tiny-slider-inner .card-img').on('click', function() {
        $('#imagepreview').attr('src', $(this).attr('src')); // here asign the image to the modal when the user click the enlarge link
        $('#imagemodal').modal('show');
    });

     $("#forget-user-password").on("submit", function(){
        $(".loader").show();
    });

    //start section datepicker used for report start date and end date
    var todaydate = new Date();
    $( ".dates" ).datepicker({
        autoclose: true,
        dateFormat: 'yy-mm-dd',
        constrainInput: false,
        endDate: todaydate,
        onSelect: function (date) {
            //Get selected date 
             var selectedDate = $('#report-start-date').datepicker('getDate');
             var selectedMonth = selectedDate.getMonth();
             var selectedYear = selectedDate.getFullYear();
         
             var lastDate = new Date(selectedYear, selectedMonth + 1, 0);
             var lastDay = moment(lastDate).format('YYYY-MM-DD');
             $('#report-end-date').val(lastDay);
             //sets minDate to txt_date_to
             $('#report-end-date').datepicker('option', 'minDate', selectedDate);
            }
    }); 

    orders.init();


});





/*Orders Listing Datepicker*/
 